import { actions, mapActions, store } from '@/store';
import anime from 'animejs';
import Init from '../common/init';

const mappedActions = mapActions(actions, store);

export default (targets, step) => {
	const init = Init();
	// const commonMenu = CommonMenu();
	const headerMenu = document.querySelector('.header__content');
	const btnBurger = document.querySelector('.burger-btn');
	let duration = 500;
	targets.style.display = step === 'leave' ? '' : 'none'

	let easing;
	if (step === 'leave') {
		easing = 'easeOutSine'
	}
	if (step === 'enter') {
		easing = 'cubicBezier(0.43, 0.195, 0.02, 1)'
	}
	const anim = anime.timeline({
		easing: easing,
		duration,
	});

	if (step === 'leave') {
		if (!headerMenu.classList.contains('header__content--opened')) {
			anim.add({
				targets,
				opacity: [1, 0],
				complete: () => {
					targets.style.display = 'none';
				}
			}, 0);
		} else {
			// commonMenu.init('exit', true).then(() => {
			// 	btnBurger.classList.remove('burger-btn--active');
			// });

			anim.add({
				targets,
				opacity: [1, 0],
				complete: () => {
					targets.style.display = 'none';
				}
			}, 0);
		}
	}
	if (step === 'enter') {
		init.beforeEnter(targets);
		easing = 'easeOutSine';
		duration = 500;

		let timeout;
		anim.add({
			targets: targets,
			opacity: [0, 1],
			easing: easing,
			duration: duration,
			begin: () => {
				targets.style.display = 'block';
				// init.enter(targets);
				window.scrollTo(0, 0);
			},
			complete: () => {
				if (store.getState().isFirstLoad) {
					mappedActions.setInitScrollbar(true)
					mappedActions.setIsReady(true)
				}
				if (jQuery('.wpcf7')) {
					jQuery('div.wpcf7 > form').each(function () {
						if (!this.querySelector('.ajax-loader')) {
							wpcf7.init(jQuery(this)[0]);
						}
						
						// if ( wpcf7.cached ) {
						//   wpcf7.refill( $(this)[0]);
						// }
					});
					if (document.querySelector('.walcf7-datetimepicker')) {
						function get_todate() {
							var today = new Date();
							var dd = today.getDate();
							var mm = today.getMonth() + 1; //January is 0!
							var yyyy = today.getFullYear();
							if (dd < 10) {
								dd = '0' + dd
							}
							if (mm < 10) {
								mm = '0' + mm
							}
							today = yyyy + '-' + mm + '-' + dd;
							return today;
						}
						function get_tomorrow() {
							var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
							var dd = tomorrow.getDate()
							var mm = tomorrow.getMonth() + 1
							var yyyy = tomorrow.getFullYear()
							if (dd < 10) {
								dd = '0' + dd
							}
							if (mm < 10) {
								mm = '0' + mm
							}
							tomorrow = yyyy + '-' + mm + '-' + dd;
							return tomorrow;
						}
						jQuery('.walcf7-datetimepicker').each(function (index, element) {
							var todate = get_todate();
							var tomorrow = get_tomorrow();
							jQuery(this).datetimepicker({
								dayOfWeekStart: 1,
								yearStart: '1900',
								locale: 'it',
								format: 'd/m/Y H:i',
								formatDate: 'Y-m-d',
								formatTime: 'H:i',
								defaultTime: '10:00',
								currentTime: '10:00',
								minTime: '09:00',
								maxTime: '19:00',
								initTime: false,
								allowInputToggle: true,
								step: 15,
								validateOnBlur: false,
								startDate: tomorrow,
								onGenerate: function (ct, $input) {
									$input.prop('readonly', true);
									var $this = jQuery(this);
									$this.find('.xdsoft_date').removeClass('xdsoft_disabled');
									$this.find('.xdsoft_time').removeClass('xdsoft_disabled');
								}
							});
						});
					}
					
				}
			}
		}, duration)

		clearTimeout(timeout)
		if (!store.getState().isFirstLoad) {
			// if (targets.dataset.barbaNamespace === 'home') {
			// 	animationsHome.init(targets, anim, duration, easing);
			// } else {
			// 	timeout = setTimeout(() => {
			// 		document.querySelector('.header-menu').style.opacity = 1;
			// 	}, duration * 2)
			// }
		} else {
			if (targets.dataset.barbaNamespace !== 'home') {
				timeout = setTimeout(() => {
					// document.querySelector('.header-menu').style.opacity = 1;
				}, duration)
				
			}
			
		}
	}

	return anim.finished;
}