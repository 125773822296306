// Dependencies
import { actions, mapActions, store } from '@/store';
import barba from '@barba/core';
import { basename } from 'path';
import { Application } from 'stimulus';
// Main scss import
import '../scss/style.scss';
// Main inits
import Init from './common/init';
import transition from './transitions/main';



const mappedActions = mapActions(actions, store);
const application = Application.start();
const loadContext = context => application.load(context.keys().map(key => ({
	identifier: basename(key.split('/').pop(), '.js').split(/(?=[A-Z])/).join('-').toLowerCase(),
	controllerConstructor: context(key).default,
})));
loadContext(require.context('../components', true, /\.js$/));

window.firstLoad = true;

// Transitions

const init = Init();

barba.hooks.beforeLeave(() => { // 'leave' with sync : true
	mappedActions.setDestroyScrollbar(true)
	mappedActions.setInitScrollbar(null)
	mappedActions.setIsReady(false)
})

barba.hooks.after(() => {
	mappedActions.setInitScrollbar(true)
})
barba.hooks.once(() => {
	// mappedActions.setIsReady(true)
})
barba.hooks.afterOnce(() => {
	window.firstLoad = false
	mappedActions.setInitScrollbar(true)
})

barba.init({
	timeout: 10000,
	transitions: [{
		once: ({ next }) => transition(next.container, 'enter', 'next'),
		leave: ({ current }) => transition(current.container, 'leave', 'next'),
		enter: ({ next }) => transition(next.container, 'enter', 'next'),
	}],
	prevent: ({ el }) => el.classList && document.querySelector('body').classList.contains('logged-in') || el.classList.contains('page-numbers') || el.classList.contains('no-barba')
});

// Emitter.on('message', function (text) {
// 	console.log(text)
// })
