import Base from '@/core/Base'
import withState from '@/mixins/withState'
@withState
export default class extends Base {
	static targets = [ 'item' ];
	
	open() {
		console.log(this.actions)
		const element = this.itemTarget
		element.classList.toggle('active')
		const panel = element.nextElementSibling
		
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px'
		}
		this.actions.setUpdateScrollbar(null)
		setTimeout(() => {
			this.actions.setUpdateScrollbar(true)
		},200)
		
	}
}