import * as UTILS from '../../js/utilities/utils';
import anime from 'animejs';

const Carousel = (() => {

	const _init = (args => {
		args.container;
		args.col = args.col ? args.col : '';
		args.colTablet = args.colTablet ? args.colTablet : '';
		args.colDesk = args.colDesk ? args.colDesk : '';
		args.offset = args.offset ? parseInt(args.offset) : 0;
		args.centered = args.centered ? true : false;
		args.autoWidth = args.autoWidth ? true : false;
		let translateAmount = 0;
		let translateAmountOld;
		let sliderWidth = 0;

		const slider = document.querySelector(args.container);

		function resize() {
			sliderWidth = -args.offset;
			const currentItem = slider.querySelector('.carousel__item--active');
			const indexItem = currentItem.dataset.index;
			const items = slider.querySelectorAll('.carousel__item');
			const offset = args.offset;
			const carouselW = slider.offsetWidth;
			let resizedTranslate = 0;
			if (args.centered) {
				resizedTranslate = -(resizedTranslate + carouselW / 2 - (currentItem.offsetWidth - args.offset) / 2);
			} else {
				resizedTranslate = 0;
			}
			for (let i = 0; i < indexItem; i++) {
				resizedTranslate = items[i].offsetWidth + offset + resizedTranslate;
			}
			items.forEach((item) => {
				sliderWidth = sliderWidth + item.offsetWidth;
			});


			anime.set(slider.querySelector('.carousel__list'), { translateX: -resizedTranslate });



			translateAmount = resizedTranslate;

		}


		if (slider) {

			const items = slider.querySelectorAll('.carousel__item');
			if (args.offset) {
				slider.querySelector('.carousel__list').style.marginLeft = -args.offset + 'px';
			}
			sliderWidth = -args.offset;

			items.forEach((item, index) => {
				item.setAttribute('data-index', index);
				// if (args.col) {
				// 	item.classList.add("col-" + args.col + "/12");
				// }
				// if (args.colTablet) {
				// 	item.classList.add("col-" + args.colTablet + "/12--tablet");
				// }
				// if (args.colDesk) {
				// 	item.classList.add("col-" + args.colDesk + "/12--desk");
				// }
				if (args.offset) {
					item.style.paddingLeft = args.offset + 'px';
				}
				if (args.autoWidth) {
					slider.querySelector('.carousel__list').classList.add('grid--auto');
				}
				// attention HACK!!!
				setTimeout(function () {
					sliderWidth = sliderWidth + item.offsetWidth;
				}, 1000);

			});
			const carouselW = slider.offsetWidth;

			const firstItemWidth = slider.querySelector('.carousel__item').offsetWidth;
			if (args.centered) {
				translateAmount = -(carouselW / 2 - (firstItemWidth - args.offset) / 2);
				anime.set(slider.querySelector('.carousel__list'), { translateX: -translateAmount });
			}



			// carouselW / 2 - (currentItemW - args.offset) / 2

			const btns = slider.querySelectorAll('[data-js-btn-c]');
			let resizeTime, currentItemW;

			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(resize, 200);
			})

			btns.forEach(el => {


				el.addEventListener('click', function () {
					const direction = this.dataset.jsBtnC;
					const currentItem = slider.querySelector('.carousel__item--active');
					const offset = parseInt(window.getComputedStyle(slider.querySelector('.carousel__item')).marginRight, '10');

					if (direction === 'next' && currentItem.dataset.index < items.length - 1 && !el.getAttribute('not-active')) {
						if (btns[0].hasAttribute('not-active')) {
							btns[0].removeAttribute('not-active');
						}
						const nextItem = UTILS.getNextSibling(currentItem);
						currentItemW = currentItem.offsetWidth + offset;
						translateAmountOld = translateAmount;
						translateAmount = translateAmount + currentItemW;
						if (translateAmount + slider.offsetWidth >= sliderWidth && !args.centered) { // 2 is a small tolerance
							translateAmount = sliderWidth - slider.offsetWidth;
							el.setAttribute('not-active', true);
						}
						slider.querySelector('.carousel__item--active').classList.remove('carousel__item--active');
						nextItem.classList.add('carousel__item--active');
						anime.remove(slider.querySelector('.carousel__list'));
						anime({
							targets: slider.querySelector('.carousel__list'),
							duration: 1200,
							translateX: [-translateAmountOld, -translateAmount],
							translateY: 0,
							translateZ: 0,
							easing: 'cubicBezier(0.215, 0.61, 0.355, 1)',
							begin: () => {
								btns[0].disabled = true;
								btns[1].disabled = true;
							},
							complete: () => {
								btns[0].disabled = false;
								btns[1].disabled = false;
							}

						})
					}
					if (direction === 'prev' && currentItem.dataset.index > 0 && !el.getAttribute('not-active')) {
						if (btns[1].hasAttribute('not-active')) {
							btns[1].removeAttribute('not-active');
						}
						const nextItem = UTILS.getPrevSibling(currentItem);
						slider.querySelector('.carousel__item--active').classList.remove('carousel__item--active');
						nextItem.classList.add('carousel__item--active');
						const currentItemW = nextItem.offsetWidth + offset;
						translateAmountOld = translateAmount;
						translateAmount = translateAmount - currentItemW;
						if (translateAmount <= 0 && !args.centered) { // 2 is a small tolerance
							translateAmount = 0;
							el.setAttribute('not-active', true);
						}
						anime.remove(slider.querySelector('.carousel__list'));
						anime({
							targets: slider.querySelector('.carousel__list'),
							duration: 1200,
							translateX: [-translateAmountOld, -translateAmount],
							translateY: 0,
							translateZ: 0,
							easing: 'cubicBezier(0.215, 0.61, 0.355, 1)',
							begin: () => {
								btns[0].disabled = true;
								btns[1].disabled = true;
							},
							complete: () => {
								btns[0].disabled = false;
								btns[1].disabled = false;
							}

						})
					}

					// items.forEach(item => {
					// 	item.querySelector("img").style.transform = "scale(1.2)";
					// 	setTimeout(() => {
					// 		item.querySelector("img").style.transform = "scale(1)";
					// 	}, 600)
					// });

					// slider.querySelector(".carousel__list").style.transform = "translate3d(" + -translateAmount + "px, 0, 0)";
				});
			});

		}

	});
	return {
		init: _init
	};
});


export default Carousel;