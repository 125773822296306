import Base from '@/core/Base';

export default class extends Base {
	onClick() {
		let node = document.createElement('div');
		node.classList.add('pop-up-wrapper');
		node.classList.add('loaded');

		const vimeoCode = this.element.dataset.vimeoId;
		const closeText = this.element.dataset.videoCloseLabel;
		node.innerHTML = `
		<div class='video-wrapper'>
			<div class='pop-up-video'>
				<button class='lato-r close-btn js-close-video'>${closeText}</button>
				<iframe src="https://player.vimeo.com/video/${vimeoCode}?autoplay=1&loop=1&color=E07B5F&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
			</div>
		</div>
		<script src="https://player.vimeo.com/api/player.js"></script>`;


		// document.getElementById("tutorials").innerHTML += "<div class='pop-up-wrapper loaded'> <div class='video-wrapper'><div class='pop-up-video'><button class='close-btn js-close-video'></button><iframe id='player' width='1180' height='663.75' src='https://www.youtube.com/embed/wejGSCM47_4?rel=0&amp;showinfo=0&amp;autoplay=1&autohide=1' frameborder='0' gesture='media' allow='encrypted-media' allowfullscreen class='tutorials-content__video'></iframe></div></div></div>";
		document.querySelector('main').appendChild(node);
		const close = document.querySelector('.js-close-video');
		const closeGlobal = document.querySelector('.pop-up-wrapper');

		close.addEventListener('click', function () {
			if (document.querySelector('.pop-up-wrapper')) {
				document.querySelector('.pop-up-wrapper').remove();
			}
		});
		closeGlobal.addEventListener('click', function () {
			if (document.querySelector('.pop-up-wrapper')) {
				document.querySelector('.pop-up-wrapper').remove();
			}
		});
	}
}