import Base from '@/core/Base';
import withState from '@/mixins/withState';
@withState

export default class extends Base {

	get scrollbarCtrl() {
		return this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller~="scroll"]'), 'scroll')
	}
	initialize() {
		super.initialize()
		const vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
		// anime({
		// 	targets: '.cow-path',
		// 	d: ['M10525.954,533.867s-2.221-1.637-2.724-1.189,1.149,2.658,1.149,2.658-1.067,1.208-.753,1.935-.952,5.629-.6,6.086a2.564,2.564,0,0,0,1.982.895c.537-.167,6.656-3.876,6.585-8.529a11.348,11.348,0,0,1-.205,1.766,24.907,24.907,0,0,0,5.331,4.024c.647.143,1.666,3.294,1.666,3.294l.478,9.212h2.955l1.014-10.661s1.241-3.21,2.3-3.241,11.617-.052,12.634,0,7.5,5.787,7.5,5.787v8.115h2.983s.368-10.5,0-11.388-2.983-4.955-2.983-4.955,1.87-7.93,1.849-8.381-.537-5.87-1.425-5.981-25.766-.48-26.8,0-10.562,9.541-11.045,9.506-2.019-3-2.518-1.705',
		// 		'M10525.954,528.867s-2.221-1.637-2.724-1.189,1.149,2.658,1.149,2.658-1.067,1.208-.753,1.935-.952,5.629-.6,6.086a2.564,2.564,0,0,0,1.982.895,8.556,8.556,0,0,0,6.585-3.529,11.348,11.348,0,0,1-.205,1.766,24.907,24.907,0,0,0,5.331,4.024c.647.143,1.666,3.294,1.666,3.294l.478,9.212h2.955l1.014-10.661s1.241-3.21,2.3-3.241,11.617-.052,12.634,0,7.5,5.787,7.5,5.787v8.115h2.983s.368-10.5,0-11.388-2.983-4.955-2.983-4.955,1.87-7.93,1.849-8.381-.537-5.87-1.425-5.981-25.766-.48-26.8,0-6.136,4.961-11.045,4.506c-.483-.034-2.019-3-2.518-1.705'],
		// 	duration: 1000,
		// 	easing: 'easeOutSine',
		// 	loop: true
		// })
		
	}
	onUpdate({
		menuIsOpen: prevMenuIsOpen,
		scrollbarIsInit: prevScrollbarIsInit
	}, { menuIsOpen,
		scrollbarIsInit
	}) {
		if (!prevMenuIsOpen && menuIsOpen) {
			// this.open()
		}
		if (prevMenuIsOpen && !menuIsOpen) {
			// this.close()
		}
		if (!prevScrollbarIsInit && scrollbarIsInit) {
			setTimeout(() => {
				this.onScroll()
			}, 100)
		}
		const openMenuBtn = document.querySelector('.burger-btn--open')
		const closeMenuBtn = document.querySelector('.burger-btn--close')
		const headerMobile = document.querySelector('.header__mobile')
		openMenuBtn.addEventListener('click', () => {
			openMenuBtn.querySelector('.burger-btn__label-menu').classList.remove('active')
			closeMenuBtn.querySelector('.burger-btn__label-back').classList.add('active')
			headerMobile.classList.add('active')
		})
		closeMenuBtn.addEventListener('click', () => {
			closeMenuBtn.querySelector('.burger-btn__label-back').classList.remove('active')
			openMenuBtn.querySelector('.burger-btn__label-menu').classList.add('active')
			headerMobile.classList.remove('active')
		})
		const langSelector = document.querySelector('.current-language')
		langSelector.addEventListener('click', () => {
			document.querySelector('.languages-toggle').classList.toggle('active')
		})
	}
	onScroll() {
		this.scrollbarCtrl.scrollbar.on('scroll', event => {
			const offset = event.scroll.y
			if (offset > 200) {
				this.element.classList.add('header--is-sticky')
			} else {
				this.element.classList.remove('header--is-sticky')
			}


		})
	}
}

/* const HamburgerMenu = () => {

	const _toggleMenu = () => {
		const commonMenu = CommonMenu();
		const btnMenu = document.querySelector('[data-js-hm-menu]');

		btnMenu.addEventListener('click', function () {
			this.classList.toggle('burger-btn--active');
			if (this.classList.contains('burger-btn--active')) {
				// headerMenu.classList.add("header__content--opened");
				document.querySelector('body').classList.add('no-scroll');
				document.querySelector('html').classList.add('no-scroll');
				commonMenu.init('enter');

			} else {
				// headerMenu.classList.remove("header__content--opened");
				document.querySelector('body').classList.remove('no-scroll');
				document.querySelector('html').classList.remove('no-scroll');
				commonMenu.init('exit');
			}

		});
	};

	return {
		init: _toggleMenu
	};
};

export default HamburgerMenu; */