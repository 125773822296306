import Base from '@/core/Base';
import withState from '@/mixins/withState';
import { Navigation, Swiper } from 'swiper';
Swiper.use(Navigation);
@withState

export default class extends Base {
	static targets = ['btnPrev', 'btnNext', 'slider', 'slide']

	onUpdate({ isReady: prevIsReady }, { isReady }) {
		if (!prevIsReady && isReady) {
			this.initCarousel()

		}
	}
	initCarousel() {
		let slidesPerView = 1.1
		if (this.slideTargets.length === 1) {
			slidesPerView = 1
		}
		let slidesPerViewDesk = 1.333
		if (this.slideTargets.length === 1) {
			slidesPerViewDesk = 1
		}
		this.swiper = new Swiper(this.sliderTarget, {
			slidesPerView: slidesPerView,
			watchOverflow: true,
			spaceBetween: 15,
			breakpoints: {
				1024: {
					slidesPerView: slidesPerViewDesk,
					spaceBetween: 15
				}
			},
			navigation: {
				nextEl: this.btnNextTarget,
				prevEl: this.btnPrevTarget
			}
		})
		this.swiper.init()
	}
}