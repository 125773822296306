import { mapActions, store, subscribe, actions } from '@/store';

export default target => class extends target {
	connectToState() {
		this.actions = mapActions(actions, store);
		this.state = store.getState();
		this.prevState = {};
		subscribe((state) => {
			this.prevState = Object.assign({}, this.state);
			this.state = state;
			if (
				typeof (this.onUpdate) === 'function'
        && this.application.controllers.includes(this)
			) {
				this.onUpdate(this.prevState, this.state);
			}
		});
	}
};
