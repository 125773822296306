import createStore from 'unistore';
import { mapActions } from 'unistore/src/util';

const store = createStore({
	isFirstLoad: true,
	isReady: null,
	isAudioPlay: true,
	menuIsOpen: null,
	scrollbarIsInit: null,
	scrollbarIsEnabled: true,
	scrollbarIsUpdated: null,
	scrollbarIsDestroyed: null,
});

const {
	subscribe,
	unsubscribe,
} = store;

const actions = () => ({
	setFirstLoad: (state, isFirstLoad) => ({ isFirstLoad }),
	setIsReady: (state, isReady) => ({isReady }),
	setIsAudioPlay: (state, isAudioPlay) => ({isAudioPlay }),
	setMenuVisibility: (state, menuIsOpen) => ({ menuIsOpen }),
	setInitScrollbar: (state, scrollbarIsInit) => ({ scrollbarIsInit }),
	setEnableScrollbar: (state, scrollbarIsEnabled) => ({ scrollbarIsEnabled }),
	setUpdateScrollbar: (state, scrollbarIsUpdated) => ({ scrollbarIsUpdated }),
	setDestroyScrollbar: (state, scrollbarIsDestroyed) => ({ scrollbarIsDestroyed })
});

export {
	store,
	actions,
	subscribe,
	unsubscribe,
	mapActions,
};
