import LocomotiveScroll from 'locomotive-scroll';
import Base from '@/core/Base'
import withState from '@/mixins/withState'

@withState
export default class extends Base {
	scrollbar = null
	static targets = [
		'scrollbar',
	]
	onUpdate({
		scrollbarIsInit: prevScrollbarIsInit,
		scrollbarIsEnabled: prevScrollbarIsEnabled,
		scrollbarIsUpdated: prevScrollbarIsUpdated,
		scrollbarIsDestroyed: prevScrollbarIsDestroyed,
	},
	{
		scrollbarIsInit,
		scrollbarIsEnabled,
		scrollbarIsUpdated,
		scrollbarIsDestroyed,
	}) {
		if (!prevScrollbarIsInit && scrollbarIsInit) {
			this.initScrollBar();		
		}
		if (!prevScrollbarIsUpdated && scrollbarIsUpdated) {
			this.updateScrollBar();
		}
		if (prevScrollbarIsEnabled && !scrollbarIsEnabled) {
			this.disableScrollBar();
		}
		if (!prevScrollbarIsEnabled && scrollbarIsEnabled) {
			this.enableScrollBar();
		}
		if (!prevScrollbarIsDestroyed && scrollbarIsDestroyed) {
			this.destroyScrollBar();
		}
	}
	initScrollBar() {
		this.scrollbar = new LocomotiveScroll({
			el: this.scrollbarTarget,
			smooth: true,
			getDirection: true
		});
	}
	updateScrollBar() {
		this.scrollbar.update()
	}
	disableScrollBar() {
		this.scrollbar.stop()
	}
	enableScrollBar() {
		this.scrollbar.start()
	}
	destroyScrollBar() {
		this.scrollbar.destroy()
	}
}

