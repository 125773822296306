// import Scrollbar from './scroll';
import { actions, mapActions, store } from '@/store';
import LazyLoad from 'vanilla-lazyload';
import SplitStrings from '../utilities/splitStrings';

const mappedActions = mapActions(actions, store);
const Init = () => {
	// const scrollbar = Scrollbar();
	// const hm = HamburgerMenu();
	const _once = () => {};

	const _beforeLeave = () => {
		mappedActions.setDestroyScrollbar(true);
		mappedActions.setInitScrollbar(null);
	}
	const _beforeEnter = container => {
		const splitStrings = SplitStrings();
		splitStrings.init();
		mappedActions.setDestroyScrollbar(false);
		
		const lazyLoadInstance = new LazyLoad({
			elements_selector: '.lazy',

			callback_reveal: function (el) {
				el.parentNode.classList.add('lazy-block--loaded');
			}
		});
	};
	const _enter = container => {
		hm.init(container);
		// scrollbar.init(container);
	};


	return {
		once: _once,
		beforeLeave: _beforeLeave,
		beforeEnter: _beforeEnter,
		enter: _enter
	}
}

export default Init;